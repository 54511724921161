import { createEffect, createResource } from 'solid-js'
import Header from '~/components/header/header'
import SubscriptionsIndividual from '~/components/subscriptions-individual/subscriptions-individual'
import { getPlans } from '~/services/stripe/plans'
import { getIndieSubscription } from '~/services/stripe/subscription'

export default function Subscribe() {
  const [plans] = createResource(getPlans)
  const [subscription] = createResource(getIndieSubscription)
  createEffect(() => {
    console.log(plans.latest, subscription.latest)
  })
  return (
    <>
      <Header />
      {plans.latest && (
        <SubscriptionsIndividual
          currentSubscription={subscription.latest}
          plans={plans.latest.individualPlans}
        />
      )}
    </>
  )
}
