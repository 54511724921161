import { Component } from 'solid-js'
import Button from '~/components/button/button'
import { SubscriptionEntryProps } from '~/components/subscription-entry/subscription-entry.interfaces'
import styles from '~/components/subscription-entry/subscription-entry.module.scss'
import { formatRecurrence } from '~/services/stripe/prices'
import { translations } from '~/translations'

const SubscriptionEntry: Component<SubscriptionEntryProps> = ({ subscription, recurrenceIndex, current, ctaLabel, onClick }) => {
  const cssClasses = () => ({
    [styles.subscriptionEntry]: true,
    [styles.current]: current
  })
  const price = () => subscription.prices[recurrenceIndex]
  
  const formattedPrice = () => `${(price().unit_amount as number/100).toFixed(2)}€`
  // const formattedRenewal = () => {
  //   const recurring = price().recurring
  //   let interval = ''
  //   let recurrenceLabel = translations().subscriptions.recurrence.singleMonth
  //   if(recurring?.interval_count && recurring?.interval_count > 1){
  //     interval = `${recurring?.interval_count} `
  //   }
  //   if(recurring?.interval === 'year'){
  //     recurrenceLabel = translations().subscriptions.recurrence.year
  //   }
  //   return `/${interval}${recurrenceLabel}`
  // }

  return (
    <div classList={cssClasses()}>
      {current && (
        <div class={styles.currentSubscription}>
          {translations().subscriptions.pro.upgrade.currentSubscription}
        </div>
      )}
      <div class={styles.label}>
        <div class={styles.labelBrand}>
          {subscription.plan.brand}
        </div>
        <div class={styles.labelTitle}>
          {subscription.plan.title}
        </div>
        {subscription.plan?.subtitle && (
          <div class={styles.labelSubtitle}>
            {subscription.plan.subtitle}
          </div>
        )}
      </div>

      <div class={styles.priceContainer}>
        <div class={styles.price}>
          <div class={styles.value}>
            {formattedPrice()}
          </div>
          <div class={styles.taxSetting}>
            HT
          </div>
        </div>
        <div class={styles.renewal}>
          /{formatRecurrence(price())}
        </div>
      </div>

      <Button
        onClick={onClick}
        style='purple'
      >
        {ctaLabel}
      </Button>
    </div>
  )
}

export default SubscriptionEntry
