import { InferOutput, literal, object } from 'valibot'
import { SubscriptionItem } from '~/types/stripe/subscription'

export const ChangeIndividualSubscriptionSchema = object({
  confirmation: literal(true)
})

export type ChangeIndividualSubscriptionType = InferOutput<typeof ChangeIndividualSubscriptionSchema>

export interface ChangeIndividualSubscriptionModalProps {
  planLabel: string
  open: boolean
  setOpen: (value: boolean) => boolean
  onConfirm: () => void
  subscriptionItems: SubscriptionItem[]
  periodEnd: Date
}
