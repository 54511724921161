import { createForm, SubmitHandler, valiForm } from '@modular-forms/solid'
import { Component, createResource, Show } from 'solid-js'
import Button from '~/components/button/button'
import { ChangeIndividualSubscriptionModalProps, ChangeIndividualSubscriptionSchema, ChangeIndividualSubscriptionType } from '~/components/change-individual-subscription-modal/change-individual-subscription-modal.interfaces'
import ToggleField from '~/components/fields/toggle-field/toggle-field'
import formStyles from '~/components/forms/styles/complex-form.module.scss'
import styles from '~/components/change-individual-subscription-modal/change-individual-subscription-modal.module.scss'
import Modal from '~/components/modal/modal'
import { getFullDate } from '~/helpers/dates'
import { previewSubscriptionUpdate } from '~/services/stripe/subscription'
import { translations } from '~/translations'
import { formatPrice, formatRecurrence } from '~/services/stripe/prices'
import { UpdateSubscriptionPayload } from '~/types/stripe/subscription'
import LoadingPlaceholder from '~/components/loading-placeholder/loading-placeholder'
import Stripe from 'stripe'
import { getPlanFromId } from '~/static/stripe'
import FormError from '~/components/form-error/form-error'

const ChangeIndividualSubscriptionModal: Component<ChangeIndividualSubscriptionModalProps> = ({ onConfirm, open, setOpen, subscriptionItems, periodEnd }) => {
  const [form, { Form, Field }] = createForm<ChangeIndividualSubscriptionType>({
    validate: valiForm(ChangeIndividualSubscriptionSchema)
  })

  const onSubmit: SubmitHandler<ChangeIndividualSubscriptionType> = async () => {
    onConfirm()
  }

  const payload = () => {
    const payload: UpdateSubscriptionPayload = {
      type: 'individual',
      subscriptionItems
    }
    return payload
  }

  const [invoices] = createResource(payload, previewSubscriptionUpdate)

  const confirmationLabel = () => {
    const label = invoices.latest?.isUpgrading
      ? translations().subscriptions.indie.upgrade.confirmImmediateBilling
      : translations().subscriptions.indie.upgrade.confirmDowngrade(getFullDate(periodEnd)) 
    return label
  }

  const formatLine = (line: Stripe.InvoiceLineItem) => {
    let result = ''

    if (line.plan?.product && typeof line.plan.product === 'string' && line.price){
      const planLabel = getPlanFromId(line.plan.product)?.title
      const recurrence = formatRecurrence(line.price)
      if (line.proration && line.amount > 0) {
        result = translations().subscriptions.newSubscriptionUpgrade(planLabel)
      } else if (line.proration && line.amount < 0) {
        result = translations().subscriptions.existingSubscriptionCredit(planLabel)
      } else {
        result = planLabel ?? ''
      }
      result = `${result} (${recurrence})`
    }

    return result
  }

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={translations().subscriptions.indie.upgrade.title}
    >
      <Show
        when={!invoices.loading}
        fallback={<LoadingPlaceholder />}
      >
        <Form onSubmit={onSubmit} class={formStyles.modalForm}>
          <div class={formStyles.fields}>
            {/* Subscription Change recap table */}
            <Show when={!invoices.latest?.isUpgrading}>
              <table class={styles.table}>
                <tbody>
                  {invoices.latest?.new.lines?.data.map(line => (
                    <tr class={styles.row}>
                      <td class={styles.description}>
                        {formatLine(line)}
                      </td>
                      <td class={styles.amount}>
                        {formatPrice(line.amount)}
                      </td>
                    </tr>
                  ))}
                  <tr class={styles.taxRow}>
                    <td class={styles.description}>
                      {translations().subscriptions.tax}
                    </td>
                    <td class={styles.amount}>
                      {formatPrice(invoices.latest?.new.tax)}
                    </td>
                  </tr>
                  <tr class={styles.totalRow}>
                    <td class={styles.description}>
                      {translations().subscriptions.total}
                    </td>
                    <td class={styles.amount}>
                      {formatPrice(invoices.latest?.new.total)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Show>

            {/* Upgrade recap table */}
            <Show when={invoices.latest?.isUpgrading}>
              <table class={styles.table}>
                <tbody>
                  {invoices.latest?.upgrade.lines?.data.map(line => (
                    <tr class={styles.row}>
                      <td class={styles.description}>
                        {formatLine(line)}
                      </td>
                      <td class={styles.amount}>
                        {formatPrice(line.amount)}
                      </td>
                    </tr>
                  ))}
                  <tr class={styles.taxRow}>
                    <td class={styles.description}>
                      {translations().subscriptions.tax}
                    </td>
                    <td class={styles.amount}>
                      {formatPrice(invoices.latest?.upgrade.tax)}
                    </td>
                  </tr>
                  <tr class={styles.totalRow}>
                    <td class={styles.description}>
                      {translations().subscriptions.total}
                    </td>
                    <td class={styles.amount}>
                      {formatPrice(invoices.latest?.upgrade.total)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Show>
            
            <Field name='confirmation' type='boolean'>
              {(field, props) => (
                <ToggleField
                  label={confirmationLabel()}
                  {...field}
                  {...props}
                />
              )}
            </Field>

            <FormError response={form.response} />

            <Button
              type='submit'
              style='lagoon'
              isLoading={form.submitting}
            >
              {translations().general.actions.edit}
            </Button>
          </div>
        </Form>
      </Show>
    </Modal>
  )
}

export default ChangeIndividualSubscriptionModal
