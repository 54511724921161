import { useNavigate } from '@solidjs/router'
import { Component, createEffect, createSignal, Show } from 'solid-js'
import Button from '~/components/button/button'
import SubscriptionEntry from '~/components/subscription-entry/subscription-entry'
import styles from '~/components/subscriptions-individual/subscriptions-individual.module.scss'
import TogglePill from '~/components/toggle-pill/toggle-pill'
import { PillOption } from '~/components/toggle-pill/toggle-pill.interfaces'
import { formatRecurrence } from '~/services/stripe/prices'
import ArrowRight from '~/assets/icons/arrow-right.svg'
import { translations } from '~/translations'
import { SubscriptionsIndividualProps } from '~/components/subscriptions-individual/subscriptions-individual.interfaces'
import { setLoadingState } from '~/services/loading/loading'
import { getCheckoutLink } from '~/services/stripe/checkout'
import { updateSubscription } from '~/services/stripe/subscription'
import { SubscriptionItem } from '~/types/stripe/subscription'
import ChangeIndividualSubscriptionModal from '~/components/change-individual-subscription-modal/change-individual-subscription-modal'
import { Plan } from '~/types/stripe/plans'

const SubscriptionsIndividual: Component<SubscriptionsIndividualProps> = ({ currentSubscription, plans }) => {
  const navigate = useNavigate()
  const [showUpdateConfirmation, setUpdateConfirmation] = createSignal(false)
  const [cart, setCart] = createSignal<SubscriptionItem[]>([])
  const [planLabel, setPlanLabel] = createSignal('')

  const [reccurenceOptions, setReccurenceOptions] = createSignal<PillOption[]>([])
  const currentRecurrenceIndex = () => {
    let index = reccurenceOptions().findIndex(option => option.checked)
    if(index === -1) index = 0
    return index
  }

  createEffect(() => {
    const recurrenceOptions: PillOption[] = plans[0].prices.map((price, index) => ({
      label: formatRecurrence(price),
      value: price.id,
      checked: index === 0
    })) ?? []
    setReccurenceOptions(recurrenceOptions)
  })

  const goToPro = () => {
    navigate('/subscribe/pro')
  }

  const onClick = (plan: Plan) => async () => {
    setPlanLabel(plan.plan.title)
    const priceForCurrentRecurrence = plan.prices[currentRecurrenceIndex()]

    setLoadingState({
      loading: true
    })
    
    const subscriptionItems: SubscriptionItem[] = [{
      price: priceForCurrentRecurrence.id,
      quantity: 1
    }]

    if(currentSubscription){
      setCart(subscriptionItems)
      setUpdateConfirmation(true)
    }else{
      const link = await getCheckoutLink(subscriptionItems)
      if (link) {
        window.location.href = link
      }
    }

    setLoadingState({
      loading: false
    })
  }

  const onConfirmUpdate = async () => {
    setLoadingState({
      loading: true
    })

    const subscriptionItems = cart()
    if(subscriptionItems) {
      await updateSubscription({
        subscriptionItems,
        type: 'individual'
      })
      navigate('/account/subscriptions')
    }

    setLoadingState({
      loading: false
    })
  }

  const isCurrent = (productId: string) => () => {
    return currentSubscription?.items.data.some(item => item.plan.product === productId) ?? false
  }

  const ctaLabel = () => currentSubscription 
    ? translations().subscriptions.indie.upgrade.cta 
    : translations().subscriptions.indie.subscribe.cta

  const titleLabel = () => currentSubscription 
    ? translations().subscriptions.indie.upgrade.title 
    : translations().subscriptions.indie.subscribe.title

  return (
    <>
      <Show when={cart().length > 0}>
        <ChangeIndividualSubscriptionModal
          planLabel={planLabel()}
          open={showUpdateConfirmation()}
          setOpen={setUpdateConfirmation}
          subscriptionItems={cart()}
          onConfirm={onConfirmUpdate}
          periodEnd={currentSubscription?.current_period_end ? new Date(currentSubscription.current_period_end * 1000) : new Date}
        />
      </Show>
      <div class={styles.subscriptionsIndividual}>
        <div class={styles.title}>
          {titleLabel()}
        </div>

        <div>
          <TogglePill
            options={reccurenceOptions()}
            setOptions={setReccurenceOptions}
          />
        </div>

        <div class={styles.plans}>
          {plans.map(entry => (
            <SubscriptionEntry
              subscription={entry}
              current={isCurrent(entry.plan.productId)()}
              recurrenceIndex={currentRecurrenceIndex()}
              onClick={onClick(entry)}
              ctaLabel={ctaLabel()}
            />
          ))}
        </div>

        <div class={styles.explanation}>
          {translations().subscriptions.taxesAtNextStep}
        </div>

        <Button
          icon={ArrowRight}
          onClick={goToPro}
          style='borderless'
        >
          {translations().subscriptions.pro.title}
        </Button>
      </div>
    </>
  )
}

export default SubscriptionsIndividual
