import { Component } from 'solid-js'
import LoadingIcon from '~/components/loading-icon/loading-icon'
import styles from '~/components/loading-placeholder/loading-placeholder.module.scss'

const LoadingPlaceholder: Component = () => {
  return (
    <div class={styles.loadingPlaceholder}>
      <LoadingIcon />
    </div>
  )
}

export default LoadingPlaceholder
